<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Promotor de vendas"
            name="Promotor"
            placeholder="Promotor"
            v-model="notaSaida.nomePromotor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="Praça"
            placeholder="Praça"
            v-model="notaSaida.praca"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Vendedor(a) atual"
            name="Vendedor(a)"
            placeholder="Vendedor(a) atual"
            v-model="notaSaida.nomeVendedor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="CPF"
            name="CPF"
            placeholder="CPF"
            v-model="notaSaida.cpf"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Data de saída atual"
            name="dataSaida"
            placeholder="Data de saída atual"
            v-model="notaSaida.dataSaidaString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data de retorno atual"
            name="dataRetorno"
            placeholder="Data de retorno atual"
            v-model="notaSaida.dataRetornoString"
            :readonly="true"
          ></v-text-field>
          <v-menu
            class="pr-2"
            ref="novaDataSaida"
            lazy
            :close-on-content-click="false"
            v-model="novaDataSaida"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="novaDataNotaSaida"
          >
            <v-text-field
              slot="activator"
              label="Nova data saída"
              v-model="novaDataNotaSaida"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="novaDataNotaSaida" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="novaDataSaida = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.novaDataSaida.save(novaDataNotaSaida)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            class="pr-2"
            ref="novaDataRetorno"
            lazy
            :close-on-content-click="false"
            v-model="novaDataRetorno"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="novaDataNotaRetorno"
          >
            <v-text-field
              slot="activator"
              label="Nova data retorno"
              v-model="novaDataNotaRetorno"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="novaDataNotaRetorno" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="novaDataRetorno = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.novaDataRetorno.save(novaDataNotaRetorno)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex">
          <v-flex lg12 sm12 xs12>
            <select-vendedor class="mr-2"></select-vendedor>
          </v-flex>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn outline @click="salvar" color="primary">Salvar</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoSaida } from "../../servicos/servicoSaida"
import SelectVendedor from "../vendedor/selectVendedor"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoSaida = new ServicoSaida()
export default {
  components: {
    SelectVendedor,
    ProgressCircular
  },
  data() {
    return {
      id: null,
      notaSaida: "",
      produtos: [],
      novaDataRetorno: false,
      novaDataSaida: false,
      novaDataNotaSaida: null,
      novaDataNotaRetorno: null,
      codVendedor: null
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoSaida.buscarPorIdResumido(this.id).then(res => {
        this.notaSaida = res.data
        this.produtos = res.data.produtos
        serverBus.$emit("notasaida", this.notaSaida.idPraca)
      })
    }
    serverBus.$on("vendedorSelected", value => {
      if (value.situacao != 1) {
        this.bloqueado = true
      } else {
        this.codVendedor = value.id
        this.bloqueado = false
      }

      if (value.situacao == 2) {
        this.snackbar = true
        this.text =
          "Vendedor bloqueado por falta de pagamento, consulte o valor da divida no cadastro ou entre em contato com a empresa."
      } else if (value.situacao == 3) {
        this.snackbar = true
        this.text = "Vendedor bloqueado por falta de devolução de mercadoria, entre em contato com a empresa."
      } else if (value.situacao == 4) {
        this.snackbar = true
        this.text = "Vendedor bloqueado por CPF negativado no SPC, entre em contato com a empresa."
      }
    })
  },
  methods: {
    voltar() {
      this.$router.push({ name: "saidaConsignada" })
    },
    salvar() {
      this.abrirProgressCircular()
      servicoSaida
        .salvarAlteracaoDeDatas(
          parseInt(this.id),
          this.notaSaida.dataSaida,
          this.notaSaida.dataRetorno,
          this.notaSaida.idVendedor,
          this.novaDataNotaSaida,
          this.novaDataNotaRetorno,
          this.codVendedor
        )
        .then(() => {
          this.fecharProgressCircular()
          this.voltar()
        })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    }
  }
}
</script>
