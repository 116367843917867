<template>
  <v-flex>
    <v-autocomplete
      label="Vendedor(a)"
      placeholder="Selecione..."
      :rules="[() => !!codVendedor || 'Campo obrigatório']"
      return-object
      v-model="codVendedor"
      :items="vendedores"
      item-value="id"
      @input="setSelected"
      item-text="textoExibir"
      required
    ></v-autocomplete>
    <v-dialog v-model="dialog" max-width="800">
      <cadastro-vendedor></cadastro-vendedor>
    </v-dialog>
    <v-snackbar :color="'success'" v-model="snackbar" :timeout="timeout" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-flex>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoVendedor } from "../../servicos/servicoVendedor"
import CadastroVendedor from "../vendedor/componentCadastroVendedor"
const servicoVendedor = new ServicoVendedor()
export default {
  components: {
    CadastroVendedor
  },
  data: () => ({
    dialog: false,
    codVendedor: null,
    vendedores: [],
    snackbar: false,
    text: "Novo vendedor adicionado com sucesso.",
    timeout: 2000,
    codPraca: ""
  }),
  created() {
    serverBus.$on("notasaida", value => {
      this.codPraca = value
      this.carregarVendedores()
    })
    serverBus.$on("romaneioSelected", value => {
      this.codPraca = value.IdPraca
      this.carregarVendedores()
    })
    serverBus.$on("fecharDialog", value => {
      this.dialog = value
      this.carregarVendedores()
    })
    serverBus.$on("mensagemSucesso", value => {
      this.snackbar = value
    })
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("vendedorSelected", value)
    },
    novoVendedor() {
      this.dialog = true
      serverBus.$emit("sempreAtivo", this.codPraca)
    },
    carregarVendedores() {
      servicoVendedor.buscarPorIdPraca(this.codPraca).then(
        res => {
          if (res.status === 200) {
            this.vendedores = res.data
          }
        }
      )
    }
  }
}
</script>
