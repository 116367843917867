import axios from "axios"

export class ServicoSaida {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Saida`
  }
  buscarSaidas() {
    return axios({
      url: this.urlServico + `/GetAllSaidasTable`,
      method: "GET"
    })
  }
  salvar(notaSaida) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", notaSaida, config)
  }
  adicionarProdutos(notaSaida) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/SalvarNovosProdutos", notaSaida, config)
  }
  buscarDetalhesRetorno(id) {
    return axios({
      url: this.urlServico + `/GetDetalhesRetorno?idSaida=${id}`,
      method: "GET"
    })
  }
  buscarProdutoNaSaida(id, codigoBarra) {
    return axios({
      url: this.urlServico + `/BuscarProdutoPorCodigoBarraNaSaida?idSaida=${id}&codigoBarra=${codigoBarra}`,
      method: "GET"
    })
  }
  buscarSaidasDoVendedor(id) {
    return axios({
      url: this.urlServico + `/GetSaidasDoVendedor?id=${id}`,
      method: "GET"
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  buscarPorIdResumido(id) {
    return axios({
      url: this.urlServico + `/GetByIdResumido?id=${id}`,
      method: "GET"
    })
  }
  salvarAlteracaoDeDatas(
    idSaida,
    dataSaidaAnterior,
    dataRetornoAnterior,
    idVendedorAnterior,
    novaDataNotaSaida,
    novaDataNotaRetorno,
    idVendedor
  ) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(
      this.urlServico + "/SalvarAlteracaoDeDatas",
      {
        idSaida,
        dataSaidaAnterior,
        dataRetornoAnterior,
        idVendedorAnterior,
        novaDataNotaSaida,
        novaDataNotaRetorno,
        idVendedor
      },
      config
    )
  }
  salvarUnificar(idSaidaOriginal, idSaidaUnificar) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(
      this.urlServico + "/SalvarUnificar",
      {
        idSaidaOriginal,
        idSaidaUnificar
      },
      config
    )
  }
}
